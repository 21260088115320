<template>
  <div class="smd-custom-select" @blur="open = false" v-clickoutside="close">
    <input type="text" class="smd-selected" ref="search" placeholder="Select" v-model="searchQuery" :class="{ open: open }" @focus="open = true">
    <div class="smd-items" :stay-open="stayOpen" :class="{ selectHide: !open }">
      <div v-if="searchOptions.length > 2" @click="selectAll()">
        <input v-if="!stayOpen" type="checkbox" :checked="allIsSelected">
        <span>
          <b>{{allIsSelected || isSelected ? 'Remove' : 'Select'}} All</b>
        </span>
      </div>
      <div v-for="option in searchOptions" :key="option.id" @click="selectOption(option)">
        <input v-if="!stayOpen" type="checkbox" :checked="selected.filter(record => record.id === option.id).length > 0">
        <span v-html="option.label"></span>
      </div>
      <div v-if="searchOptions.length === 0">
        <span>No results found</span>
      </div>
    </div>
    <div v-if="!stayOpen" class="smd-close" :class="{ selectHide: !open }" @click="open = false">
      <center>Close</center>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array, // can be either.
      required: true
    },
    identifier: {
      type: String,
      required: true
    },
    default: {
      type: [Number, String],
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    stayOpen: {
      type: Boolean,
      required: false,
      default: false
    },
    isSelected: {
      type: Boolean,
      required: false,
      default: false
    },
    selectedOptions: {
      type: Array,
      required: false
    }
  },
  data () {
    return {
      open: this.stayOpen,
      selected: [],
      key: null,
      searchQuery: '',
      isDisabled: false
    }
  },
  mounted () {
    if (this.isSelected) {
      this.selected = this.options
    }
  },
  watch: {
    open () {
      if (!this.open && this.stayOpen) this.open = true
    },
    selectedOptions () {
      // Options changed. Reset selected.
      this.selected = this.selectedOptions
    }
  },
  computed: {
    allIsSelected () {
      return this.selected.length === this.options.length
    },
    searchOptions () {
      if (this.searchQuery !== '') return this.options.filter(record => record.label.toLowerCase().includes(this.searchQuery.toLowerCase()))

      return this.options
    }
  },
  methods: {
    setOpen (isOpen = null) {
      if (!this.disabled && isOpen === null) {
        this.open = !this.open
      } else if (isOpen !== null) {
        this.open = isOpen
      }
    },
    close () {
      this.open = false
    },
    selectAll () {
      if (this.allIsSelected) {
        this.selected = []
      } else {
        this.selected = []
        this.selected = [...this.options]
      }

      this.$emit('onchange', this.selected)
    },
    selectOption (option) {
      if (this.searchQuery !== '') {
        this.searchQuery = ''
        this.$refs.search.focus()
      }

      if (this.selected.filter(record => record.id === option.id).length > 0) this.selected = this.selected.filter(_record => _record.id !== option.id)
      else this.selected.push(option)
      this.$emit('onchange', this.selected)
    }
  }
}
</script>

<style scoped>

.smd-custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 1.35rem;
  font-size: .75rem!important;
  line-height: 35px;
  height: 100%;
}

.smd-custom-select .smd-selected {
  background-color: white;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  border: 1px solid #ededed;
  border-color: #dbdbdb;
  border-radius: 4px;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  outline: none!important;
  height: 36px;
  width: 100%;
}

.smd-custom-select .smd-selected[disabled="disabled"] {
  background-color: lightgray;
  border: 1px solid #ededed;
  padding-left: 1em;
  cursor:not-allowed;
  user-select: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-radius: 6px;
}

.smd-custom-select .smd-selected.open {
  border: 1px solid #ededed;
  border-radius: 6px 6px 0px 0px;
}

.smd-custom-select .smd-selected:after {
  position: absolute;
  content: "";
  top:  17px;
  right: .75em;
  width: 20;
  height: 0;
  border: 5px solid transparent;
  border-color: #444444 transparent transparent transparent;
}

.smd-custom-select .smd-items {
  border-radius: 0px;
  margin-top: 0px;
  overflow: hidden;
  overflow-y: scroll;
  max-height: 200px;
  width: 100%;
  background-color: white;
  border: 1px solid #ededed;
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  z-index: 999;
}

.smd-custom-select .smd-items[stay-open="true"] {
  min-height: 100%;
  max-height: 300px;
  height: 100%;
}

.smd-custom-select .smd-close {
  border-radius: 0px 0px 6px 6px;
  width: 100%;
  background-color: whitesmoke;
  border-left: 1px solid #ededed;
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  cursor: pointer;
  z-index: 900;
}

.smd-custom-select .smd-close:hover {
  background-color: #ececec;
}

.smd-custom-select .smd-items div {
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.smd-custom-select .smd-items div:hover {
  background-color: whitesmoke;
}

.selectHide {
  display: none;
}

input {
  margin-right: 1em;
}
</style>
